export const APPLICATION_URL = `${process.env.SERVICE_API}/srv`;
export const SERVICE_API_PATH = `${process.env.SERVICE_API_PATH}`;

export const HTTP_METHODS = {
  POST: "POST",
  PUT: "PUT",
  GET: "GET",
  DELETE: "DELETE",
};

export const SERVICE_ERROR = "SERVICE_ERROR";
export const STYLE = { color: "rgb(170, 170, 170)" };
